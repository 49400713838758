




































import { Component, Vue } from 'vue-property-decorator'
import { AuthModule, UserModule } from '@/store/modules'
import fillStore from '@/services/fillStore'

type DropdownItem = {
  name: string
  to: Nullable<string>
  icon: string
  divider?: boolean
  action: Nullable<Function>
}

@Component({
  components: {
    UserDropdownItem: () => import('./UserDropdownItem.vue')
  }
})
export default class UserDropdown extends Vue {
  @AuthModule.Action logout
  @UserModule.State currentUser

  items: DropdownItem[] = [
    { name: 'Profile', to: '/profile', icon: 'UserIcon', action: () => null },
    { name: 'Refresh my data', to: null, icon: 'RotateCwIcon', action: () => fillStore() },
    { name: 'Settings', to: '/settings', icon: 'SettingsIcon', divider: true, action: () => null },
    { name: 'Logout', to: null, icon: 'LogOutIcon', action: this.logoutAndRedirect }
  ]

  logoutAndRedirect() {
    this.logout()
    this.$router.replace({ name: 'login' })
  }
}
